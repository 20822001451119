import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { InternationalOutletsResponse } from "@/types/next"; 

interface InternationalOutletsState {
    data: InternationalOutletsResponse | null;
    loading: boolean;
    error: string | null;
}

// Initial state
const initialState: InternationalOutletsState = {
    data: null,
    loading: false,
    error: null,
};

// Async thunk to fetch international outlets
export const fetchInternationalOutlets = createAsyncThunk(
    "internationalOutlets/fetch",
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch("/api/v1/get-city-based-international-outlets");
            if (!response.ok) {
                throw new Error("Failed to fetch international outlets");
            }
            const data: InternationalOutletsResponse = await response.json();
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message || "Something went wrong");
        }
    }
);

const internationalOutletsSlice = createSlice({
    name: "internationalOutlets",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInternationalOutlets.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchInternationalOutlets.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchInternationalOutlets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || "Failed to load data";
            });
    },
});

export default internationalOutletsSlice.reducer;
