import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state type
/**
 * Represents the state of visitor information.
 * @typedef {Object} VisitorInfoState
 * @property {number | null} latitude - The latitude of the visitor, or null if not available.
 * @property {number | null} longitude - The longitude of the visitor, or null if not available.
 */

export interface VisitorInfoState {
  latitude: number | null;
  longitude: number | null;
  mobileNumber?: string // capture when user login time return response user not exist
  selectedCountry: string;
  countryCode?: string;
}

// Define the initial state
const initialState: VisitorInfoState = {
  latitude: null,
  longitude: null,
  mobileNumber: '',
  countryCode: '',
  selectedCountry: '+91'

};

// Create the slice
/**
 * Slice for managing visitor location information.
 * @typedef {Object} VisitorInfoSlice
 * @property {(state: VisitorInfoState, action: PayloadAction<{ latitude: number; longitude: number }>) => void} setLocation - Action to set the visitor's location.
 * @property {(state: VisitorInfoState) => void} clearLocation - Action to clear the visitor's location.
 */
const visitorInfoSlice = createSlice({
  name: 'visitorInfo',
  initialState,
  reducers: {

    setMobNumber(state, action: PayloadAction<{ mobileNumber: string; countryCode?: string }>) {
      state.mobileNumber = action.payload.mobileNumber;
      state.countryCode = action.payload.countryCode || state.countryCode;
    },
    /**
    * Sets the visitor's selected country code.
    * @param {VisitorInfoState} state - The current state.
    * @param {PayloadAction<string>} action - The action containing the new country code.
    */
    setSelectedCountry(state, action: PayloadAction<string>) {
      state.selectedCountry = action.payload;
    },
    /**
     * Sets the visitor's location.
     * @param {VisitorInfoState} state - The current state.
     * @param {PayloadAction<{ latitude: number; longitude: number }>} action - The action containing the new location.
     */
    setGeoLocation(state, action: PayloadAction<{ latitude: number; longitude: number }>) {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    /**
     * Clears the visitor's location.
     * @param {VisitorInfoState} state - The current state.
     */
    clearGeoLocation(state) {
      state.latitude = null;
      state.longitude = null;
    },
  },
});

// Export actions and reducer
export const { setGeoLocation, clearGeoLocation, setMobNumber, setSelectedCountry } = visitorInfoSlice.actions;
export default visitorInfoSlice.reducer;
